<template>
<div>
  <div id="back">
    <a href="/user">back</a>
  </div>
<div id="reference">
    
    <ul>gettting things done</ul>
    <ul>how to read a book</ul>
    <ul>毛泽东选集第一卷</ul>
    <ul>微习惯</ul>
    <ul>原则</ul>
    <ul>练习的心态</ul>
    <ul>a mind for number</ul>
    <ul>摩托车维修的艺术</ul>   
    <ul>贫穷的本质</ul> 
    <ul>务实思考的技术</ul> 
    <ul>穷查理宝典</ul> 
    <ul>项目管理的思考</ul>
    <ul>公司组织与运营</ul>
    <ul>dont make me think</ul>
    <ul>如何阅读一本书</ul>
    <ul></ul> 
</div>
</div>
</template>

<script>
export default {
  name: 'reference',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#back{
  position: absolute;;
  left: 10%;
}
#reference{
  font-family: fangsong;
  text-align: left;
  position:absolute;
  left: 40%;
}
</style>
